.popupWrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fefefe;
    margin: 15% auto;
    border: 1px solid #888;
    width: 80%;
    max-width: 350px;
    border-radius: 5px;
}

.overlayModal {
    position: fixed;
    z-index: 9999999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /*overflow: auto;*/
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

.popupFooter{
    display: flex;
    border-top: 1px solid gray;
    align-items: end;
    padding: 10px;
    gap: 10px;
    justify-content: end;
    flex-direction: row;
}

.closeBtn {
    color: black;
    right: 10px;
    cursor: pointer;
    top: 0px;
    position: absolute;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.openInApp{
    background-color: rgb(76,175,80);
    color: white;
    font-weight: bold;
    font-size: 20px;
    width: 200px;
    padding: 10px;
    align-self: center;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 10px;
}

