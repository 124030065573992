.how-it-works {
    text-align: center;
    padding: 2rem;
    background-color: #ffffff; /* Example background color */
    font-family: -apple-system, Nunito Sans,
    sans-serif;
  }
  
  .steps-container {
    display: flex;
    justify-content: space-around; /* Adjusted to space-around to give more room */
    position: relative; /* Needed for absolute positioning of pseudo-elements */
    padding: 0; /* Adjusted padding to 0 */
    max-width: 80%; /* Increased max width */
    width: 80%; /* Ensures it takes full width up to the max */
    margin: auto; /* Center the container */
  }
  
  .step {
    flex: 0 1 60%; /* Adjust flex-basis to reduce the width of each step */
    max-width: 60%; /* Sync max-width with flex-basis */
    width:60%;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 1rem; /* Space between steps */
  }
  
  .step-image {
    width: 100px;
    height: auto;
    margin-bottom: 1rem;
  }
  
  .step-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 0.2rem;
    
    font-weight: bold;
  }
  
  .step-description {
    font-size: 18px;
    color: #898A8D;
  }


  
  /* Desktop view: show the horizontal dotted lines */
  @media (min-width: 769px) {
    .curvy-line {
      display: block; /* Show the SVG dotted lines */
      width: 40%; /* Reduced width of the SVG lines */
      margin: 20px; /* Center the SVG in the container */
    }
  
    .step::after {
      /* Remove the pseudo-element styles that might affect layout */
      content: none;
    }
  }
  
  /* Mobile view adjustments */
  @media (max-width: 768px) {
    .curvy-line {
      /* Hide the SVG dotted lines on mobile */
      display: none;
    }
  
    .steps-container {
      justify-content: center;
      flex-wrap: wrap;
      max-width: none; /* Allow the container to take full width */
    }
  
    .step {
      flex-basis: 100%; /* Full width steps on mobile */
      max-width: 100%;
      margin-bottom: 2rem; /* Increase space between steps */
    }
  }
  
  @media (max-width: 480px) {
    .steps-container {

      justify-content: center;
    }
  
    .step {
      margin-bottom: 3rem; /* Increase space between steps if needed */
    }
  
    /* Add vertical dotted lines between steps */
    .step:not(:last-child)::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 100%;
      width: 2px;
      height: 3rem; /* Height of the vertical line, adjusted for new spacing */
      border-left: 2px dotted #ccc;
      transform: translateX(-50%);
      display: block;
    }
  }
  