.custom-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.732);
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-alert {
  background: white;
  padding: 2vw;
  border-radius: 1vw;
  text-align: center;
  box-shadow: 0 0 1vw rgba(0, 0, 0, 0.3);
  font-size: 1.4vw;
  z-index: 1000;
}

.custom-alert button {
  margin-top: 1vw;
  padding: 0.8vw 1.7vw;
  border: none;
  border-radius: 1vw;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 1.2vw;
  font-weight: 800;
}

.custom-alert button:hover {
  background-color: #0056b3;
}

@media (max-width: 767px) {
  .custom-alert {
    font-size: 4vw;
    padding: 4vw;
    border-radius: 3vw;
  }
  .custom-alert button {
    font-size: 4vw;
    padding: 2vw 4vw;
    border-radius: 3vw;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .custom-alert {
    font-size: 4vw;
    padding: 3vw;
    border-radius: 3vw;
  }
  .custom-alert button {
    font-size: 3vw;
    padding: 1.5vw 3vw;
    border-radius: 2vw;
  }
}
