.infoWrapper{
    color: black;
    background: white;
    width: 160px;
    height: 150px;
    display: flex;
    border-radius: 5px;
    flex-direction: column;
    align-items: center;
    border: 1px solid gray;
    padding: 20px;
    text-align: center;
    justify-content: center;
}

.wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 40px;
    font-family: "Poppins", sans-serif !important;
    align-items: center;
    justify-content: center;
}

.header{
    padding-top: 10px;
    height: 60px;
    position: sticky;
    top: 0;
    text-transform: capitalize;
    background: white;
    padding-bottom: 10px;
    width: 100%;
    padding-left: 20px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
}

.logoWrapper{
    display: flex;
}

.logoWrapper h3{
    align-self: baseline;
}

.header img{
    height: 40px;
    width: 40px;
    margin-right: 10px;
}

h3{
    margin: 0;
}

.infoIcon{
    color: var(--primary);
    font-size: 30px;
}

.shopName{
    text-align: center;
}

.shopInfo{
    display: flex;
    width: 100%;
    color: black;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.shopInfo img{
    width: 100%;
    height: auto;
}

.shopInfo>div{

}

.productsWrapper{
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    gap: 30px;
    align-items: center;
    justify-content: center;
}

.imageWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    margin-top: 5px;
    border:1px solid rgba(20,20,20,0.2)
}


.imageWrapper img{
    height: 90%;
    width: auto;
    max-width: 200px;

}

.productWrapper{
    height: 350px;
    width: 250px;
    gap: 20px;
    border-radius: 6px;
    padding: 10px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    border: 1px solid gray;

}

.detailWrapper{
    align-self: flex-start;

}

.footer{
    padding: 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    background: rgb(20,20,20);
    color: white;
}

.productName{
    font-size: 16px;
    font-weight: 500;
}

.mrp{
    font-size: 17px;
    font-weight: 800;
    color: var(--primaryLanding);;;
}

.price{
    font-weight: 800;
    color: black;
    font-size: 22px;
    white-space: pre;
}

.offersWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 0px;
}

.heading{
    align-items: center;
    padding: 10px 20px;
    text-align: center;
    display:inline;
    border-bottom: 3px solid black;
}

.carousel{
    display: flex;
    gap: 20px;
    overflow: auto;
}

.offersWrapper img{
    width: 500px;
    max-width: 100%;
    height: 100%;
}

.downloadButton {
    background: var(--primaryLanding);;
    color: white;
    padding: 10px 15px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 400;
    margin-right: 20px;
}

.downloadButtonFooter{
    text-decoration: none !important;
    margin-top: 20px;
    background: var(--primaryLanding);
    color: white;
    margin-bottom: 10px;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 8px;
    font-size: 18px;
}

.downloadButtonFooter:hover{
    color: white;
}

.stickyDownload{
    width: 100%;
    bottom:0px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;

}

.downloadAnchor{
    text-decoration: none;
}

.productHeader{
    display: flex;
    align-items: center;
    justify-content: center;
}

.downloadLayout{
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.buttonLayout{
    display: flex;
    justify-content: space-between;
}

.downloadBtn{
    background: var(--primaryLanding);
    width: 120px;
    height: 30px;
    align-items: center;
    text-align: center;
    gap: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    color: white;

}

.notFound{
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

/* smartphones, iPhone, portrait 480x320 phones */
@media (min-width:320px)  {
    .downloadButton{
        display: none;
    }

    .header{
        justify-content: flex-start;
    }
}
/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
@media (min-width:481px)  {
    .downloadButton{
        display: none;
    }
    .header{
        justify-content: flex-start;
    }
}

/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
@media (min-width:641px)  {
    .downloadButton{
        display: block;
    }
    .header{
        justify-content: space-between;
    }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width:961px)  {
    .downloadButton{
        display: block;
    }
    .header{
        justify-content: space-between;
    }
}

/* big landscape tablets, laptops, and desktops */
@media (min-width:1025px) {
    .downloadButton{
        display: block;
    }
    .header{
        justify-content: space-between;
    }
}

/* hi-res laptops and desktops */
@media (min-width:1281px) {
    .downloadButton{
        display: block;
    }
    .header{
        justify-content: space-between;
    }
}
