
.features-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    font-family: -apple-system, Nunito Sans,
    sans-serif;
    background: linear-gradient(to bottom, #ffffff 50%, #4527A0 50%); /* Adjust the blue color as needed */
  }
  
  .features-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 50%; /* Overlay only the top half */
     /* This is the overlay color */
    z-index: 0; /* Ensures the overlay is below the content */
  }
  
  
  .features-section::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%; /* Adjust to match the gradient area */
    background: linear-gradient(to bottom, #ffffff 0%, #4527A0 100%);
    z-index: -1; /* Put the gradient behind the content */
  }
  
  .features-container {
    position: relative;
    z-index: 1; /* Ensures the feature cards are above the overlay */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
  
  .app-image {
    width: 80%;
    height: 80vh; /* Take up half the viewport height */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }
  
  /* .overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 50%; 
  } */
  
  .features-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    width: 80%;
    margin-top: 40px;
  }

  .feature-card h3 {
    font-size: 22px;
    font-weight: bold;
  }

  .feature-card p{
    font-size: 16px;
    color: #898A8D;
  }
  
  
  .feature-card {
    flex: 1 1 calc(20% - 20px); /* Four cards in a row, minus the gap */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin: 10px;
    min-height: 200px; /* Adjust as needed */
    display: wrap;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    color: #000;
    background-color: #ffffff;
  }
  
  .feature-card img {
    width: 80px; /* Adjust as needed */
    height: 80px; /* Adjust as needed */
    margin-bottom: 20px;
  }
  
  @media (max-width: 1024px) {
    .feature-card {
      flex: 1 1 calc(33.333% - 20px); /* Three cards in a row on smaller screens */
    }
  }
  
  @media (max-width: 768px) {
    .feature-card {
      flex: 1 1 calc(50% - 20px); /* Two cards in a row on mobile screens */
    }
  }
  
  @media (max-width: 480px) {
    .feature-card {
      flex: 1 1 100%; /* One card per row on very small screens */
    }
  }
  