/* AboutUs.css */

.about-container {
  font-family: -apple-system, Nunito Sans,
  sans-serif;
    max-width: 1200px;
    margin: auto;
    padding: 20px;
  }
  
  .our-story {
    margin-bottom: 50px;
  }
  
  .our-story h2 {
    margin-bottom: 10px;
    color: #333;
    text-align: center;
  }

  .our-story h1 {
    margin-bottom: 10px;
    color: #333;
    text-align: center;
  }
  
  .our-story p {
    font-size: 18px;
    line-height: 1.6;
    color: #666;
  }
  
  .connecting-kiranas {
    background-color: #4527A0;
    padding: 20px;
    text-align: center;
  }
  
  .connecting-kiranas h1 {
    color: #ffffff;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  
  .connecting-kiranas img {
    width: 100%; /* Adjust as needed */
    height: auto;
    margin-top: 20px;
  }

  /* TeamSection.css */

.team-section {
    text-align: center;
    margin: 50px 0;
  }
  
  .team-section h2 {
    margin-bottom: 40px;
  }

  .team-section h1 {
    margin-bottom: 40px;
  }
  
  .team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
  }
  
  .team-member {
    width: 300px; /* Adjust the width as needed */
  }
  
  .team-member .team-icon {
    display: block; /* Ensures the icon appears on a new line */
    margin: 10px auto; /* Centers the icon and adds some spacing */
    font-size: 24px; /* Adjust the size as needed */ /* Adjust the color as needed */
    width: 20px;
    height: 20px;
    text-decoration: none;
    /* Add more styling as needed */
  }

  .team-member .team-icon i{
    display: block; /* Ensures the icon appears on a new line */
    margin: 10px auto; /* Centers the icon and adds some spacing */
    font-size: 24px; /* Adjust the size as needed */ /* Adjust the color as needed */
    width: 20px;
    height: 20px;
    text-decoration: none;
    decoration: none;
    /* Add more styling as needed */
  }



  .team-member img {
    width: 50%;
    height: 150px;
    border-radius: 50%; /* Makes the image round */
    margin-bottom: 10px;
  }
  
  .team-member h3 {
    font-size: 24px;
    margin-bottom: 5px;
  }
  
  .team-member p {
    font-size: 18px;
    color: #666;
  }

  /* StorySection.css */

.story-section {
    max-width: 1200px; /* Adjust width as needed */
    margin:  auto;
    padding: 20px;
  }
  
  .story-section h2 {

    text-align: center;
    margin-bottom: 30px;
  }

  .story-section h1 {

    text-align: center;
    margin-bottom: 30px;
  }
  
  .story-section p {
    font-size: 18px;
    line-height: 1.6;
    text-align: justify; /* This ensures the text is evenly spaced */
    margin-bottom: 20px;
    color: #333; /* Adjust the color as needed */
  }
  