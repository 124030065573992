/* PricingSection.css */
.pricing-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  }
  
  @media (max-width: 600px) {
    .pricing-section {
      flex-direction: column;
      align-items: center;
    }
  }
  