/* PricingCard.css */
.card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    flex: 1;
    max-width: 300px; /* adjust as needed */
  }

  .card .title {
    font-size: 18px;
    font-weight: bold;
  }
  
  .card-primary {
    background-color: #4527A0;
    color: white;
  }

  .card-primary .price {
    color: #4CAF50;
  }

  .card-primary button {
    background-color: transparent; /* Make the button background transparent */
    color: #fff; /* Set the text color to match the card background */
    border: 2px solid #4CAF50; /* Make the border match the card background */
  }
  
  .card .price {
    font-size: 24px;
    font-weight: bold;
    color: #4CAF50;
  }
  
  button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: auto; /* Push the button to the bottom of the card */
  }

  .card-primary button:hover {
    background-color: #5243aa; /* Set the background color on hover */
    color: white; /* Set the text color to white on hover */
  }

  
  button:hover {
    background-color: #50e;
  }
  
  @media (max-width: 600px) {
    .card {
      margin: 10px 0;
    }
  }
  