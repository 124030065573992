.main-div {
  height: 100vh;
  background-color: #f4f5fa;
  .top-main {
    height: 10%;
    background-color: #ffffff;
  }
  .topleft img {
    width: 3vw;
  }
  .not-found-div {
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .not-found-div-image img {
      width: 50vw;
    }
    .button-div {
      margin-top: 4vw;
      width: 30%;
      display: flex;
      justify-content: space-evenly;
    }
    .action-button {
      background-color: #4527a0;
      padding: 0.75vw 2vw;
      font-size: 1vw;
      border-radius: 0.4vw;
    }
  }
}
@media (max-width: 767px) {
  .main-div {
    .topleft {
      padding-left: 4vw;
      gap: 3vw;
    }
    .topleft img {
      width: 12vw;
    }
    .topleft p {
      display: block;
      font-size: 6vw;
    }
    .not-found-div {
      .not-found-div-image img {
        width: 90vw;
      }
      .button-div {
        margin-top: 10vw;
        width: 90%;
        display: flex;
        justify-content: space-evenly;
      }
      .action-button {
        background-color: #4527a0;
        padding: 1.75vw 5vw;
        font-size: 3.5vw;
        border-radius: 1.5vw;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .main-div {
    .topleft {
      padding-left: 4vw;
      gap: 3vw;
    }
    .topleft img {
      width: 18vw;
    }
    .topleft p {
      display: block;
      font-size: 4vw;
    }
    .not-found-div {
      .not-found-div-image img {
        width: 90vw;
      }
      .button-div {
        margin-top: 10vw;
        width: 90%;
        display: flex;
        justify-content: space-evenly;
      }
      .action-button {
        background-color: #4527a0;
        padding: 1.75vw 5vw;
        font-size: 3.5vw;
        border-radius: 1vw;
      }
    }
  }
}
