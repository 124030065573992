.no-shop {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.no-shop img {
  width: 5vw;
}
.no-shop p {
  font-size: 3vw;
  font-weight: bold;
}
.main-div {
  .top-main {
    display: flex;
    padding: 1vw 2vw;
    align-items: center;
    background-color: #ffffff;
    position: sticky;
    justify-content: space-between;
  }
  .topleft {
    display: flex;
    width: 30%;
    gap: 0.5vw;
    align-items: center;
  }
  .topleft p {
    font-size: 1.8vw;
    font-weight: bold;
    color: #232323;
    margin: 0;
    padding: 0;
  }
  .topright {
    display: flex;
    flex-direction: column;
    width: fit-content;
    justify-content: flex-end;
    align-items: flex-start;
  }
  .ordertitle {
    color: black;
    font-size: 1vw !important;
  }
  .mobile-apps {
    display: flex;
    gap: 0.7vw;
    align-items: center;
  }
  .order-button {
    display: none;
  }
  .body-main {
    background-color: #f4f5fa;
  }
  .shop-image {
    display: flex;
    justify-content: space-between;
    padding: 4vw 3vw 1vw 3vw;
    flex-direction: row;
  }
  .main-image {
    width: 65%;
  }
  .shop-location {
    width: 35%;
  }
  .shop-name {
    background-color: #856ec5;
    width: 80%;
    margin: auto;
    height: 4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 2.2vw;
    font-weight: 600;
    border-top-left-radius: 0.5vw;
    border-top-right-radius: 0.5vw;
  }
  .shed-img {
    display: flex;
    justify-content: center;
    margin-top: -0.2vw;
  }
  .shed-img img {
    width: 100%;
    z-index: 100;
  }
  .image-div {
    display: flex;
    justify-content: center;
    margin-top: -2vw;
    height: 22vw;
  }
  .image-div img {
    width: 84%;
    height: 100%;
    border: 1vw solid white;
  }
  .shop-location {
    padding: 0 0 1vw 3vw;
  }
  .shop-location p {
    color: #402d9a;
    font-weight: bold;
    font-size: 1vw;
  }
  .shop-address {
    background-color: #ffffff;
    padding: 1vw 8vw 1vw 1vw;
    border-radius: 0.7vw;
    margin-bottom: 1vw;
    border: 1px solid rgb(222, 221, 221);
    font-size: 1vw;
    color: black;
  }
  .location-area img {
    width: 100%;
  }
  .offer-main {
    padding: 1vw 3vw;
    margin-top: 1vw;
  }
  .offer-top {
    display: flex;
    justify-content: flex-end;
  }
  .offer-title {
    font-size: 2vw;
    color: #402d9a;
    font-weight: bold;
    padding: 0 3vw;
    margin-top: 1.5vw;
  }
  .nav-button {
    display: flex;
    gap: 0.5vw;
    width: 50%;
    justify-content: flex-end;
  }
  .nav-button img {
    cursor: pointer;
  }
  .offer-bottom {
    margin-top: 1vw;
  }
  .single-offer {
    height: 30vw;
    margin: 1vw;
  }
  .offer-content {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .video-content {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .offer-cards {
    margin-top: 1vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 1vw 3vw;
    justify-content: space-between;
    gap: 5vw 10vw;
    width: 100%;
    margin: auto;
  }
  .first-card {
    width: 100%;
    display: flex;
    background-color: #aa8ef8;
    color: #402d9a;
    padding: 2vw 2vw;
    border-radius: 1.5vw;
  }
  .second-card {
    width: 88%;
    display: flex;
    background-color: #d7f2d2;
    color: #255453;
    padding: 2vw 2vw;
    border-radius: 1.5vw;
  }
  .offer-desc {
    width: 60%;
  }
  .offer-desc h2 {
    font-weight: 700;
    font-size: 2.5vw;
    margin: 0;
  }
  .offer-desc h4 {
    font-weight: 700;
    font-size: 1.6vw;
    width: 60%;
    line-height: 2vw;
  }
  .offer-desc p {
    margin-top: 1vw;
    font-size: 2vw;
    font-weight: 900;
  }
  .offer-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
  }
  .offer-image img {
    width: 80%;
  }
  .blur-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    z-index: 999;
  }
  .button-apps4 {
    position: fixed;
    background-color: #ffffff;
    border-radius: 0.8vw;
    width: 25vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    padding: 3vw;
  }
  .top-popup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5vw;
    font-weight: 600;
  }
  .top-popup img {
    cursor: pointer;
  }
  .top-popup p {
    margin: 0;
  }
  .web-apps {
    margin: 2vw 0;
  }
  .web-heading {
    color: #402d9a;
    font-weight: 700;
    font-size: 1vw;
  }
  a {
    text-decoration: none;
  }
  .individual-apps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25vw 0.5vw;
    border-radius: 0.5vw;
    margin-bottom: 0.5vw;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  .name-logo {
    display: flex;
    justify-content: flex-start;
    gap: 0.5vw;
    align-items: center;
  }
  .offer-text {
    background-color: red;
    color: yellow;
    padding: 0.2vw 0.4vw;
    border-radius: 1vw;
    font-size: 0.6vw !important;
    font-weight: 700;
  }
  .individual-apps p {
    margin: 0;
    font-size: 1vw;
  }
  .condition-div {
    display: flex;
    justify-content: flex-end;
    margin-top: 2vh;
    font-size: 0.5vw;
  }
  .button-apps1 {
    border: 1px solid rgb(222, 221, 221);
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: -66vw;
    left: 3vw;
    background-color: #ffffff;
    padding: 1vw;
    border-radius: 1vw;
    z-index: 100;
  }
  .button-apps2 {
    border: 1px solid rgb(222, 221, 221);
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: -66vw;
    left: 52.5vw;
    background-color: #ffffff;
    padding: 1vw;
    border-radius: 1vw;
    z-index: 100;
  }
  .button-apps3 {
    border: 1px solid rgb(222, 221, 221);
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: -3vw;
    left: 2vw;
    background-color: #ffffff;
    padding: 1vw;
    border-radius: 1vw;
    z-index: 100;
    width: 14vw;
  }
  .left-offer {
    color: white;
    border: 1px solid white;
    border-radius: 4vw;
    background-color: #402d9a;
    padding: 0.7vw 2vw;
    font-size: 0.8vw;
  }
  .right-offer {
    color: white;
    border: none;
    border-radius: 4vw;
    background-color: #255453;
    padding: 0.7vw 2vw;
    font-size: 0.8vw;
  }
  .category-div {
    margin-top: 2vw;
    padding: 1vw 3vw;
  }
  .category-top {
    display: flex;
    justify-content: space-between;
  }
  .category-title {
    font-size: 2vw;
    color: #402d9a;
    font-weight: bold;
  }
  .category-nav-button {
    display: flex;
    gap: 0.5vw;
    width: 50%;
    justify-content: flex-end;
  }
  .category-nav-button img {
    width: 4%;
    cursor: pointer;
  }
  .all-categories {
    margin-top: 2vw;
  }
  .single-category {
    width: 10vw;
    padding: 0 0.8vw;
    height: 3vw;
    background-color: #402d9a;
    color: #ffffff;
    border-radius: 2.5vw;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .single-category img {
    width: 100%;
  }
  .product-desc {
    max-height: 40vw;
    min-height: 6vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .single-category p {
    margin: 0;
    padding: 0;
    font-size: 0.8vw;
    line-height: 0.8vw;
    text-align: center;
  }
  .product-div {
    padding: 1vw 3vw;
  }
  .product-heading {
    font-size: 2vw;
    color: #402d9a;
    font-weight: bold;
    margin-top: 1.5vw;
  }
  .all-products {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2vw;
    margin-top: 1vw;
  }
  .single-product {
    background-color: #ffffff;
    position: relative;
    padding: 0.5vw 0.9vw;
    border-radius: 1.5vw;
    border: 1px solid rgb(222, 221, 221);
  }
  .product-image {
    display: flex;
    justify-content: center;
    height: 9vw;
    width: 6vw;
    padding-top: 2.5vw;
    margin: auto;
  }
  .product-image img {
    width: 100%;
    object-fit: contain;
  }
  .product-type-quan {
    margin-top: 0.5vw;
    color: #bdc1ca;
    font-size: 0.9vw;
  }
  .product-name {
    font-weight: 500;
    margin: 0;
    font-size: 1.1vw;
    text-align: center;
    color: #565d6d;
  }
  .product-price {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 1.2vw;
    padding: 1vw 0 0 0;
    color: #565d6d;
  }
  .strike-price {
    text-decoration: line-through;
  }
  .product-order-button-div {
    display: flex;
    justify-content: center;
    padding-bottom: 0.6vw;
  }
  .product-order-button {
    margin-top: 1vw;
    background-color: #17b400;
    color: #ffffff;
    border-radius: 3vw;
    padding: 0.5vw 2.5vw;
    font-size: 1.4vw;
    cursor: pointer;
  }
  .product-order-button.disabled-button {
    background-color: grey;
  }
  .footer-main {
    margin: 2vw 0 0 0;
    background-color: #402d9a;
    color: #ffffff;
    padding: 0.5vw 3vw;
    display: flex;
    align-items: center;
    gap: 10vw;
    flex-direction: row;
  }
  .footer-left {
    width: 50%;
  }
  .footer-left-heading {
    font-size: 2vw;
    margin-bottom: 1vw;
    display: flex;
    justify-content: flex-start;
  }
  .button-apps {
    display: none;
  }
  .nav-apps {
    padding: 1vw;
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5vw;
    border-radius: 1vw;
    background-color: #ffffff;
    margin-bottom: 1vw;
  }
  .footer-right {
    width: 25vw;
    text-align: center;
    font-size: 1vw;
  }
  .footer-main.no-left .footer-right {
    margin: 0 auto;
    width: 25%;
    justify-content: center;
    padding: 2vw 0;
  }
  .address-div {
    margin-top: 1vw;
  }
  .mobile-shop-location {
    display: none;
  }
  .product-offer-div {
    position: absolute;
    top: 1vw;
    left: 1vw;
    border-radius: 4vw;
    padding: 0.2vw 1vw;
    background-color: #e89c2d;
    color: #ffffff;
    font-weight: 700;
    border: 2px solid #a66f20;
  }
  .map-view {
    width: 30vw;
    height: 20vw;
    border: none;
  }
}
.blured-div {
  background-color: yellow;
}

@media (max-width: 767px) {
  .no-shop img {
    width: 20vw;
  }
  .no-shop p {
    font-size: 10vw;
  }
  .main-div {
    .topleft p {
      display: none;
    }
    .order-button {
      display: block;
    }
    .order-button:hover {
      background-color: #4caf50;
    }
    .ordertitle,
    .mobile-apps {
      display: none;
    }
    .shop-image {
      flex-direction: column;
      padding: 4vw 2vw 1vw 2vw;
    }
    .main-image {
      width: 100%;
    }
    .image-div {
      height: 45vw;
    }
    .shop-location {
      display: none;
    }
    .offer-main {
      padding: 1vw 5vw;
    }
    .offer-title {
      padding: 0 5vw;
      font-size: 6vw;
    }
    .nav-button {
      gap: 2vw;
    }
    .nav-button img {
      width: 12%;
      cursor: pointer;
    }
    .offer-top {
      margin-bottom: 2vw;
    }
    .offer-cards {
      grid-template-columns: repeat(1, 1fr);
      gap: 4vw;
      width: 100%;
    }
    .first-card,
    .second-card {
      width: 94%;
      margin: auto;
      border-radius: 4vw;
      padding: 5vw 3vw;
      position: relative;
    }
    .blur-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(10px);
      z-index: 999;
    }
    .button-apps4 {
      border-radius: 1.5vw;
      width: 75vw;
      padding: 5vw;
    }
    .top-popup {
      font-size: 5.5vw;
    }
    .web-apps {
      margin: 4vw 0;
    }
    .web-heading {
      font-size: 4vw;
    }
    .individual-apps {
      padding: 0.5vw 1vw;
      border-radius: 1vw;
      margin-bottom: 2vw;
    }
    .name-logo {
      gap: 2vw;
    }
    .offer-text {
      padding: 0.5vw 1vw;
      border-radius: 2vw;
      font-size: 2.5vw !important;
    }
    .individual-apps p {
      font-size: 4vw;
    }
    .condition-div {
      margin-top: 2vh;
      font-size: 2vw;
    }
    .offer-desc {
      width: 73%;
    }
    .offer-desc h2 {
      font-size: 8vw;
    }
    .offer-desc h4 {
      font-size: 4.5vw;
      line-height: 6vw;
    }
    .offer-desc p {
      font-size: 6vw;
    }
    .offer-image {
      width: 25%;
    }
    .offer-image img {
      width: 100%;
    }
    .left-offer {
      margin-top: 2vw;
      font-size: 3vw;
    }
    .right-offer {
      margin-top: 2vw;
      font-size: 3vw;
    }
    .category-title {
      font-size: 6vw;
      width: 80%;
    }
    .category-nav-button {
      gap: 2vw;
    }
    .category-nav-button img {
      width: 18%;
    }
    .all-products {
      grid-template-columns: repeat(2, 1fr);
    }
    .single-product {
      padding: 1.8vw;
    }
    .product-price {
      font-size: 3.8vw;
    }
    .product-name {
      font-size: 3.5vw;
    }
    .product-type-quan {
      font-size: 3.5vw;
    }
    .product-order-button {
      font-size: 4vw;
    }
    .single-category {
      height: 15vw;
      width: 45vw;
      border-radius: 8vw;
      text-align: center;
    }
    .single-category p {
      font-size: 3.5vw;
      font-weight: 500;
      line-height: 4vw;
    }
    .product-heading {
      font-size: 6vw;
    }
    .product-desc {
      max-height: 90vw;
      min-height: 20vw;
    }
    .footer-main {
      flex-direction: column;
    }
    .footer-left {
      width: 100%;
    }
    .footer-main.no-left .footer-right {
      width: 60%;
      padding: 6vw 0;
    }
    .button-apps {
      border: 1px solid rgb(222, 221, 221);
      display: flex;
      justify-content: flex-end;
      position: absolute;
      top: 14vw;
      right: 2vw;
      background-color: #ffffff;
      padding: 2vw;
      border-radius: 2vw;
    }
    .button-apps1 {
      border: 1px solid rgb(222, 221, 221);
      display: flex;
      justify-content: flex-end;
      position: absolute;
      bottom: -14vw;
      left: 2vw;
      background-color: #ffffff;
      padding: 2vw;
      border-radius: 2vw;
      z-index: 100;
    }
    .button-apps2 {
      border: 1px solid rgb(222, 221, 221);
      display: flex;
      justify-content: flex-end;
      position: absolute;
      bottom: -14vw;
      left: 2vw;
      background-color: #ffffff;
      padding: 2vw;
      border-radius: 2vw;
      z-index: 100;
    }
    .button-apps3 {
      border: 1px solid rgb(222, 221, 221);
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: -8vw;
      left: 5vw;
      background-color: #ffffff;
      padding: 1vw;
      border-radius: 1vw;
      z-index: 100;
      width: 35vw;
    }
    .button-apps3 img {
      width: 20%;
    }
    .nav-apps {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto auto;
      place-content: center;
      width: fit-content;
      margin: auto;
      padding: 3vw;
      border-radius: 4vw;
    }
    .nav-apps img {
      width: 15vw;
    }
    .category-div {
      padding: 4vw;
    }
    .footer-left-heading {
      margin-top: 2vw;
      justify-content: center;
      font-size: 4vw;
    }
    .footer-right {
      font-size: 3vw;
      width: 70vw;
    }
    .single-offer {
      height: 100vw;
      margin: 1vw;
    }
    .product-div {
      padding: 4vw;
    }
    .category-top {
      padding: 2vw;
    }
    .mobile-shop-location {
      display: block;
      padding: 3vw;
      .mobile-address {
        font-size: 5vw;
      }
      .shop-address {
        background-color: #ffffff;
        padding: 4vw 4vw 4vw 4vw;
        border-radius: 2vw;
        margin-bottom: 5vw;
        border: 1px solid rgb(222, 221, 221);
        font-size: 3vw;
        color: black;
      }
    }
    .mobile-shop-location p {
      color: #402d9a;
      font-weight: bold;
      font-size: 4vw;
    }
    .product-offer-div {
      position: absolute;
      top: 3vw;
      left: 3vw;
      border-radius: 4vw;
      padding: 0.2vw 1vw;
    }
    .product-image {
      padding-top: 9vw;
      height: 25vw;
      width: 25vw;
    }
    .product-image img {
      width: 100%;
    }
    .map-view {
      width: 94vw;
      height: 75vw;
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .no-shop img {
    width: 15vw;
  }
  .no-shop p {
    font-size: 8vw;
  }
  .main-div {
    .topleft p {
      display: none;
    }
    .order-button {
      display: block;
    }
    .ordertitle,
    .mobile-apps {
      display: none;
    }
    .shop-image {
      flex-direction: column;
      padding: 4vw 2vw 1vw 2vw;
    }
    .main-image {
      width: 100%;
    }
    .image-div {
      height: 35vw;
    }
    .shop-location {
      display: none;
    }
    .offer-main {
      padding: 1vw 4vw;
    }
    .offer-title {
      font-size: 5vw;
      padding: 0 4vw;
    }
    .nav-button {
      gap: 2vw;
    }
    .nav-button img {
      width: 12%;
      cursor: pointer;
    }
    .offer-top {
      margin-bottom: 2vw;
    }
    .offer-cards {
      grid-template-columns: repeat(1, 1fr);
      gap: 4vw;
      width: 95%;
    }
    .first-card,
    .second-card {
      width: 100%;
      margin: auto;
      border-radius: 4vw;
      padding: 5vw 3vw;
    }
    .blur-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(10px);
      z-index: 999;
    }
    .button-apps4 {
      border-radius: 1.5vw;
      width: 70vw;
      padding: 5vw;
    }
    .top-popup {
      font-size: 5.5vw;
    }
    .web-apps {
      margin: 4vw 0;
    }
    .web-heading {
      font-size: 3.5vw;
    }
    .individual-apps {
      padding: 1vw 1vw;
      border-radius: 1vw;
      margin-bottom: 2vw;
    }
    .name-logo {
      gap: 2vw;
    }
    .offer-text {
      padding: 0.5vw 1vw;
      border-radius: 2vw;
      font-size: 2vw !important;
    }
    .individual-apps p {
      font-size: 2.5vw;
    }
    .condition-div {
      margin-top: 2vh;
      font-size: 2vw;
    }
    .offer-desc {
      width: 73%;
    }
    .offer-desc h2 {
      font-size: 8vw;
    }
    .offer-desc h4 {
      font-size: 4.5vw;
      line-height: 6vw;
    }
    .offer-desc p {
      font-size: 6vw;
    }
    .category-div {
      padding: 3vw;
    }
    .offer-image {
      width: 25%;
    }
    .offer-image img {
      width: 100%;
    }
    .left-offer {
      margin-top: 2vw;
      font-size: 3vw;
    }
    .right-offer {
      margin-top: 2vw;
      font-size: 3vw;
    }
    .category-title {
      font-size: 5vw;
    }
    .category-nav-button {
      gap: 2vw;
    }
    .category-nav-button img {
      width: 12%;
    }
    .all-products {
      grid-template-columns: repeat(2, 1fr);
    }
    .single-product {
      padding: 2vw;
    }
    .product-price {
      font-size: 3.5vw;
    }
    .product-order-button {
      font-size: 3vw;
    }
    .product-name {
      font-size: 3vw;
    }
    .product-type-quan {
      font-size: 3vw;
    }
    .single-category {
      height: 10vw;
      width: 30vw;
      border-radius: 8vw;
    }
    .single-category p {
      font-size: 2.5vw;
      line-height: 2vw;
      line-height: 2.8vw;
    }
    .product-heading {
      font-size: 5vw;
    }
    .product-desc {
      max-height: 65vw;
      min-height: 20vw;
    }
    .footer-main {
      flex-direction: column;
    }
    .footer-left {
      width: 100%;
    }
    .footer-main.no-left .footer-right {
      width: 60%;
      padding: 6vw 0;
    }
    .nav-apps {
      width: fit-content;
      margin: auto;
      padding: 3vw;
      border-radius: 4vw;
    }
    .nav-apps img {
      width: 15vw;
    }
    .footer-left-heading {
      margin-top: 2vw;
      justify-content: center;
      font-size: 4vw;
    }
    .footer-right {
      font-size: 3vw;
      width: 70vw;
    }
    .single-offer {
      height: 90vw;
      margin: 1vw;
    }
    .product-div {
      padding: 3vw;
    }
    .category-top {
      padding: 2vw;
    }
    .mobile-shop-location {
      display: block;
      padding: 3vw;
      .shop-address {
        background-color: #ffffff;
        padding: 4vw 4vw 4vw 4vw;
        border-radius: 2vw;
        margin-bottom: 4vw;
        border: 1px solid rgb(222, 221, 221);
        font-size: 3vw;
        color: black;
      }
    }
    .mobile-shop-location p {
      color: #402d9a;
      font-weight: bold;
      font-size: 4vw;
    }
    .product-offer-div {
      position: absolute;
      top: 3vw;
      left: 3vw;
      border-radius: 4vw;
      padding: 0.2vw 1vw;
    }
    .product-image {
      padding-top: 7vw;
      height: 25vw;
      width: 25vw;
    }
    .map-view {
      width: 92vw;
      height: 70vw;
    }
  }
}
